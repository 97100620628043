import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Button from '@/Components/Button';
import useForm from '@/Hooks/useForm';
import route from 'ziggy-js';
import API from '@/Services/API';
import { unstable_usePrompt, useLocation, useNavigate, useParams } from 'react-router-dom';
import reactRoute from '@/Utilities/routes';
import { useAuth } from '@/Contexts/AuthContext';
import { db } from '@/Services/IndexedDB';
import WeeklyReportForm from '@/Pages/Projects/Reports/WeeklyReportForm';
import { Report } from '@/Types/Models/Projects/report';
import Loader from '@/Components/Loader';
import { Project } from '@/Types/Models/Projects/project';
import { useLayout } from '@/Contexts/LayoutContext';
import ObservationForm from '@/Pages/Projects/Reports/ObservationForm';
import SnagForm from '@/Pages/Projects/Reports/SnagForm';
import { clearAllTinyMceData } from '@/Utilities/helpers';


const Show: FunctionComponent = () => {
	const { setHeader, setAction } = useLayout();
	const { projectId, id } = useParams();
	const { data, setData, loadData, errors, loadErrors } = useForm<Report>({});
	const navigate = useNavigate();
	const { offline, mobileMode, calculatePhotoCount } = useAuth();
	const { state } = useLocation();
	const [project, setProject] = useState<Project | null>({
		id: projectId ?? state?.project?.id
	});
	const [report, setReport] = useState<Report | null>( null);
	const [isLocalCopy, setIsLocalCopy] = useState<boolean>(false);
	const [processing, setProcessing] = useState<boolean>(false);

	const getData = useCallback((projectId: string | number, reportId: string | number, offline: boolean | undefined) => {
		const notFound = () => {
			if (!offline) {
				console.log('fetching from server');
				API.get(route('api.projects.reports.show', [projectId, reportId]), {
					headers: {
						'PWA-No-Cache': 'true'
					}
				}).then(({ data }) => {
					setReport(data.data);
					loadData(data.data);
				});
			} else {
				console.log('offline');
				navigate(reactRoute('not-found'));
			}
		};

		console.log(typeof projectId === 'string' ? parseInt(projectId) : projectId);
		db.projects.get(typeof projectId === 'string' ? parseInt(projectId) : projectId).then(project => {
			console.log(project);
			if (project) {
				let foundReport = undefined;

				if (reportId === undefined || reportId === 'undefined') {
					foundReport = project.reports.find((report: Report) => report?.type === state?.type && report?.id === undefined);
				} else {
					foundReport = project.reports.find((report: Report) => report?.id === parseInt(reportId as string));
				}

				console.log(project, foundReport, report);
				setProject(project as Project);
				if (foundReport) {
					setReport(foundReport as Report);
					loadData(foundReport as Report);
				} else {
					if (report) {
						loadData(report);
					}
				}
				setIsLocalCopy(true);
			} else {
				notFound();
			}

		}).catch((e) => {
			alert(e);
			notFound();
		});
	}, []);

	const handleBack = () => {
		window.location.href = reactRoute('projects.show', [project?.id], {
			tab: 'inspection'
		});
	};

	useEffect(() => {
		const onUnload = (e: BeforeUnloadEvent|PageTransitionEvent) => {
			e.preventDefault();
			e.returnValue = 'Are you sure you like to go back. Any unsaved changes will be lost.';
		};

		window.addEventListener('beforeunload', onUnload);
		// window.addEventListener('pagehide', onUnload);

		return () => {
			window.removeEventListener('beforeunload', onUnload);
			// window.removeEventListener('pagehide', onUnload);
		};
	}, []);

	useEffect(() => {
		if (project?.id && id) {
			getData(project?.id, id, offline);
		}
	}, [id]);

	useEffect(() => {
		setAction && setAction(
			<>
				{
					state?.canEdit && (
						<Button type="button" className="btn-success" onClick={submit}>Update Report</Button>
					)
				}
			</>
		);

		if (state?.type || report?.type) {
			setHeader && setHeader(<div className="flex w-100 gap-5">
				<Button type="button" className="back-link" onClick={handleBack}>

					<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M18.2 273l-17-17 17-17L171.8 85.4l17-17 33.9 33.9-17 17L93.1 232 424 232l24 0 0 48-24 0L93.1 280 205.8 392.6l17 17-33.9 33.9-17-17L18.2 273z"/></svg>
				</Button>
				<h2 className="leading-tight my-auto">{state?.canEdit ? 'Edit' : 'View'} {state?.type ?? report?.type}</h2>
			</div>);
		}
	}, [data]);

	const submit = () => {
		if (state?.canEdit) {
			setProcessing(true);
			if (offline || mobileMode || report?.id === undefined) {
				if (project?.id) {
					db.projects.update(project?.id, {
						...project,
						reports: [
							...(project?.reports?.filter((report: Report) => report.id != id && report.id != undefined) ?? []),
							...(project?.reports?.filter((report: Report) => report.id == undefined && report.type !== data?.type) ?? []),
							data
						]
					}).then(() => {
						clearAllTinyMceData();
						localStorage.setItem('flash-success', `${report?.type} has been updated in the local database. When you are back online, please sync to the server.`);
						mobileMode && calculatePhotoCount && calculatePhotoCount();
					})
						.finally(() => setProcessing(false));
				}
			} else {
				if (project?.id) {
					API.patch(route('api.projects.reports.update', [project?.id, report?.id]), data).then(({ data: response }) => {
						clearAllTinyMceData();
						if (response.id) {
							localStorage.setItem('flash-success', `${report?.type} has been updated on the server.`);
						}
					})
						.catch(({ response }) => loadErrors(response?.data?.errors))
						.finally(() => setProcessing(false));
				}
			}
		}
	};

	if (report === null) {
		return <Loader/>;
	}

	return (
		<div className="ReportEditPage float-left panel-outer w-full block bg-white shadow-sm p-5">
			{
				(state?.type || report?.type) === 'Weekly Report' && (
					<WeeklyReportForm canEdit={(state?.canEdit !== false)} data={data} setData={setData} errors={errors}/>)
			}
			{
				(state?.type || report?.type) === 'Defect and Observation Report' && (
					<ObservationForm canEdit={(state?.canEdit !== false)} data={data} setData={setData} errors={errors}/>)
			}
			{
				(state?.type || report?.type) === 'Snag Report' && (
					<SnagForm canEdit={(state?.canEdit !== false)} data={data} setData={setData} errors={errors}/>)
			}
			{
				(state?.canEdit !== false) && (
					<div className="flex clear-both w-full float-left justify-end mt-6">
						<Button type="button" className="ml-3 btn-success" onClick={submit} processing={processing}>Update Report</Button>
					</div>
				)
			}
			
		</div>
	);
};

export default Show;
